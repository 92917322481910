import React from "react"

import { Section, SubSection, PLink } from "./base"
import { SectionTemplate } from "./sections";

const reactStringReplace  = require("react-string-replace");

export class ContactDiv extends React.Component {
  render() {
    const {
      sectionName = "Contact",
      sectionLabelRight = "",
      sectionClassName = "section-div",
      onClickFadeOut,
      ignoreScrollTop,
    } = this.props
    return (
      <Section
        sectionName={sectionName}
        sectionLabelRight={sectionLabelRight}
        sectionClassName={sectionClassName}
      >
        <SubSection>
          <span className="big-section-text font-medium">
            {" "}
            Are we the partners you’ve been searching for?{" "}
            <PLink
              to={"contact"}
              className="p-link p-link--dashed"
              onClickFadeOut={onClickFadeOut}
              ignoreScrollTop={ignoreScrollTop}
            >
              Tell us about your requirements.
            </PLink>
          </span>
        </SubSection>
      </Section>
    )
  }
}

export class CustomContactDiv extends React.Component {
    render() {
        const {section, onClickFadeOut, ignoreScrollTop} = this.props;
        const {text = "Are we the partners you’ve been searching for? Tell us about your requirements.", highlighted = "Tell us about your requirements."} = this.props.content;
        return <SectionTemplate details={section}>
            <SubSection className="big-section-text font-medium">
                {
                    reactStringReplace(text, highlighted, (match, i) => (
                        <PLink
                        to={"contact"}
                        className="p-link p-link--dashed"
                        onClickFadeOut={onClickFadeOut}
                        ignoreScrollTop={ignoreScrollTop}
                        >{match}</PLink>
                    ))
                }
            </SubSection>
        </SectionTemplate>
    }
}

export class ContactDivDteams extends React.Component {
  render() {
    const {
      sectionName = "Contact",
      sectionLabelRight = "",
      sectionClassName = "section-div",
      onClickFadeOut,
      ignoreScrollTop,
    } = this.props
    return (
      <Section
        sectionName={sectionName}
        sectionLabelRight={sectionLabelRight}
        sectionClassName={sectionClassName}
      >
        <SubSection>
          <span className="big-section-text font-medium">
            {" "}
            Want to find out more about building distributed teams?{" "}
            <PLink
              to={"contact"}
              className="p-link p-link--dashed"
              onClickFadeOut={onClickFadeOut}
              ignoreScrollTop={ignoreScrollTop}
            >
              Get in touch today.
            </PLink>
          </span>
        </SubSection>
      </Section>
    )
  }
}
