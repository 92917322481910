import React from "react"
import { Link, navigate } from "gatsby"
import SiteLogo from "../../../content/assets/prosource-logo-color-centre.png"
import SiteLogoMD from "../../../content/assets/prosource-icon-512x512-180x180.png"
import AboutHoverVid from "../../../content/assets/video/about.mp4"
import ApproachHoverVid from "../../../content/assets/expertisecovers/videos/Prosource-Final.mp4"

import AboutImage from "../../../content/assets/about.jpg"
import { NavBarLinks } from "../../../content/pages/nav"
import { responsive } from "../../utils/constants"

import ExpertisePeek from "../main-page-hover-layout"

import { PLink } from "./base"

import Collapse from "react-bootstrap/Collapse"
import ReactResizeDetector from "react-resize-detector"

const colors = {
  "psg-black": "#2a2f36",
  "psg-white": "#F4F4F4",
}

function HandleResize(width, height, isMainPage) {
  const { mobile, tablet } = responsive

  if (isMainPage) {
    width += 30 // because of the width
  }

  if (width > tablet) {
    // console.log("desktop")
    return { isMobile: false, isTablet: false, isDesktop: true }
  } else if (width >= mobile && width <= tablet) {
    // console.log("tablet")
    return { isMobile: false, isTablet: true, isDesktop: false }
  } else {
    // console.log('mobile')
    return { isMobile: true, isTablet: false, isDesktop: false }
  }
}

function isMainPageFunc({ location }) {
  return location.pathname === "/"
}

/* Partial Classes */

class SiteHeader extends React.Component {
  render() {
    const { open, children, isMobile } = this.props
    return (
      <header
        className={`site-head${open ? " site-head--menu-open" : ""}${
          isMobile ? " site-head--mobile" : ""
        }`}
      >
        {children}
      </header>
    )
  }
}

class SiteHeadLogoDiv extends React.Component {
  render() {
    const {
      onClick = null,
      onMouseOver = null,
      onMouseOut = null,
      open,
    } = this.props

    if (open) {
      return ""
    } else {
      return (
        <div className="site-head-logo-div" id="site-head-logo-div">
          <div
            className="site-head-logo-wrapper"
            id={"site-head-logo-wrapper"}
            onClick={onClick}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
          >
            <img src={SiteLogoMD} alt="Logo" className="site-head-logo" />
          </div>
        </div>
      )
    }
  }
}

class SiteHeadMenuDiv extends React.Component {
  render() {
    const { open, isDesktop, children, style, isTablet } = this.props

    if (isDesktop || isTablet) {
      return (
        <Collapse in={open}>
          <div className="site-head-menu-container" style={style}>
            {children}
          </div>
        </Collapse>
      )
    } else {
      return (
        <div
          className="site-head-menu-container-mobile animated fadeInRight"
          id="site-head-menu-container-mobile"
          style={style}
        >
          {children}
        </div>
      )
    }
  }
}

/* Main Class */

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      defaultMode: true,
      previewMode: false,
      open: false,
      menuHover: "",
      isMobile: false,
      isTablet: false,
      isDesktop: true,
      menuMount: false,
      width: 0,
      height: 0,
    }

    this.handleLogoHover = this.handleLogoHover.bind(this)
    this.handleLogoHoverOut = this.handleLogoHoverOut.bind(this)
    this.handleLogoClick = this.handleLogoClick.bind(this)

    this.handleMenuClick = this.handleMenuClick.bind(this)
    this.handleMenuHover = this.handleMenuHover.bind(this)

    this.manageBordersHover = this.manageBordersHover.bind(this)
    this.manageSiteMainAndFooter = this.manageSiteMainAndFooter.bind(this)
    this.manageDocumentBodyStyle = this.manageDocumentBodyStyle.bind(this)
  }

  manageBordersHover(borderWidth) {
    this.refs.siteHeadBorderContainer.style.borderWidth = borderWidth
  }

  manageSiteMainAndFooter(mode) {
    let SiteMainDiv = document.getElementById("site-main"),
      SiteFooter = document.getElementById("site-foot")

    if (SiteMainDiv) SiteMainDiv.style.display = mode
    if (SiteFooter) SiteFooter.style.display = mode
  }

  manageDocumentBodyStyle(style) {
    document.body.style.background = style
  }

  handleLogoHover() {
    const { isDesktop } = this.state
    if (isDesktop) {
      this.manageBordersHover(`90px`)
      this.refs.siteHeadBorderContainer.style.zIndex = 1
      this.refs.siteHeadWorkWithUs.style.display = "none"
    }
  }

  handleLogoHoverOut() {
    const { isDesktop } = this.state
    if (isDesktop) {
      this.manageBordersHover(`0px`)
      this.refs.siteHeadBorderContainer.style.zIndex = -1
      this.refs.siteHeadWorkWithUs.style.display = "block"
    }
  }

  handleLogoClick = event => {
    let MobileMenu = document.getElementById("site-head-menu-container-mobile"),
      headerActive = this.props.headerActive,
      SiteWrapperDiv = document.getElementById("site-wrapper")

    const { open, isMobile, menuMount, isTablet } = this.state,
      isMainPage = isMainPageFunc(this.props)

    // console.log(`isMobile ${isMobile} isTablet ${isTablet} isDesktop ${isDesktop}`)

    if (!menuMount) this.setState({ menuMount: true })

    if (open) {
      // this will close the menu
      SiteWrapperDiv.classList.remove("site-wrapper--menu-open")

      if (isMainPage) {
        // if main page
        this.manageDocumentBodyStyle(``) // unset background
      } else {
        this.manageDocumentBodyStyle(colors["psg-black"]) // change bg back to black
      }

      if (isMobile) {
        // closes the mobile menu
        MobileMenu.classList.add("fadeOutRight") // add fade out right animation, this executes immediately
        setTimeout(() => {
          // 0.5 sec delay
          MobileMenu.classList.remove("fadeOutRight") // remove fade out right animation afterwards
          this.manageSiteMainAndFooter("block")
          this.setState({ open: !open }) // change state of navigation menu
        }, 500)
      } else {
        // for tablet and desktop, this closes the menu
        if (isTablet) {
          this.manageBordersHover(`0px`)
        }

        this.manageSiteMainAndFooter("block")
        this.setState({ open: !open, menuHover: "" }) // remove the state for menu hover
      }
    } else {
      // this will open the menu
      this.manageDocumentBodyStyle(colors["psg-white"])
      this.manageSiteMainAndFooter("none")

      SiteWrapperDiv.classList.add("site-wrapper--menu-open")

      this.setState({ open: !open, menuHover: headerActive })
      this.refs.siteHeadBorderContainer.style.zIndex = -1
    }
  }

  handleMenuHover = event => {
    const { menuHover, isMobile } = this.state,
      type = event.nativeEvent.type
    let newMenuHoverState = ""

    if (!isMobile) {
      const currentTarget = event.currentTarget.dataset.section

      if (type === "mouseover") {
        newMenuHoverState = currentTarget
      }

      if (menuHover !== newMenuHoverState) {
        if (type === "mouseover") {
          if (
            (menuHover === "About" && newMenuHoverState === "Approach") ||
            (menuHover === "Approach" && newMenuHoverState === "About")
          ) {
            this.setState({ menuHover: "" })
            setTimeout(() => {
              this.setState({ menuHover: newMenuHoverState })
            }, 100)
          } else {
            this.setState({ menuHover: newMenuHoverState })
          }

          this.refs.siteHeadMenuPreview.classList.add(
            "preview-fade-leave",
            "preview-fade-leave-active"
          )
          setTimeout(() => {
            this.refs.siteHeadMenuPreview.classList.remove(
              "preview-fade-leave",
              "preview-fade-leave-active"
            )
            this.refs.siteHeadMenuPreview.classList.add(
              "preview-fade-enter",
              "preview-fade-enter-active"
            )
          }, 290)
        }
      }
    }
  }

  handleMenuClick = event => {
    event.preventDefault()
    const navigateLink = event.currentTarget.getAttribute("href")
    if (navigateLink) {
      this.handleLogoClick()
      setTimeout(() => {
        this.handleLogoHoverOut()
        navigate(navigateLink)
      }, 300)
    }
  }

  onResize = (width, height) => {
    this.setState(HandleResize(width, height, isMainPageFunc(this.props)))
  }

  render() {
    const {
        menuHover,
        open,
        isDesktop,
        isMobile,
        menuMount,
        isTablet,
      } = this.state,
      { location } = this.props
    let PreviewDiv = ``

    switch (menuHover) {
      case "Expertise":
        PreviewDiv = (
          <div className={`site-head-menu-preview--expertise`}>
            <ExpertisePeek></ExpertisePeek>
          </div>
        )
        break
      case "Approach":
        PreviewDiv = (
          <div
            className={`site-head-menu-preview-vid`}
            ref={`siteHeadMenuPreviewVidApproach`}
          >
            <div className="video">
              <video
                width={`100%`}
                height={`100%`}
                title={`Approach Video`}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
                autoPlay
                loop={false}
                style={{ objectPosition: `center -40vh` }}
              >
                <source src={ApproachHoverVid} type="video/mp4" />
              </video>
            </div>
          </div>
        )
        break
      case "About":
        PreviewDiv = (
          <div className={`site-head-menu-preview-vid`}>
            <div className="video">
              <video
                width={`100%`}
                height={`100%`}
                title={`About Video`}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
                autoPlay
                loop={false}
                style={{ objectPosition: `center -20vh` }}
              >
                <source src={AboutHoverVid} type="video/mp4" />
              </video>
            </div>
          </div>
        )
        break
      case "":
        PreviewDiv = ``
        break
      default:
        PreviewDiv = (
          <div
            className={`site-head-menu-preview--on`}
            style={{ backgroundImage: `url(${AboutImage})` }}
          ></div>
        )
        break
    }

    return (
      <ReactResizeDetector handleWidth handleHeight onResize={this.onResize}>
        <div
          className="site-head-border-container"
          key="site-border"
          ref={`siteHeadBorderContainer`}
        ></div>
        <SiteHeader
          open={open}
          isDesktop={isDesktop}
          isMobile={isMobile}
          isTablet={isTablet}
          key="site-head"
        >
          <SiteHeadLogoDiv
            open={open}
            onClick={this.handleLogoClick}
            onMouseOver={this.handleLogoHover}
            onMouseOut={this.handleLogoHoverOut}
          />
          <SiteHeadMenuDiv
            open={open}
            isDesktop={isDesktop}
            isTablet={isTablet}
            ref={"siteHeadMenuDiv"}
            style={{ display: menuMount ? "" : "none" }}
          >
            <div className="site-head-menu-logo-div">
              <div
                className="site-head-menu-logo-wrapper"
                onClick={this.handleLogoClick}
              >
                <img src={SiteLogo} alt="Logo" className="site-head-logo" />
              </div>
            </div>
            <div
              className="site-head-menu-work-with-us"
              ref={`siteHeadWorkWithUsInMenu`}
            >
              <PLink
                to={`contact`}
                onClick={this.handleMenuClick}
                data-section={`Contact`}
              >
                Work With Us
              </PLink>
            </div>
            <div className="site-head-menu-list-wrapper">
              <ul className="site-head-menu-list-items">
                {NavBarLinks.map(({ text, link, description }, index) => {
                  return (
                    <li
                      className="site-head-menu-list-item"
                      key={`site-head-menu-list-item-${index}`}
                      onMouseOver={this.handleMenuHover}
                      onMouseOut={this.handleMenuHover}
                      onClick={this.handleMenuClick}
                      data-section={text}
                    >
                      <Link
                        to={link}
                        onClick={this.handleMenuClick}
                        className="site-head-menu-list-item-link"
                      >
                        <span className="site-head-menu-list-item-text">
                          {text}
                        </span>
                        <hr className="site-head-menu-list-item-hr" />
                        <span className="site-head-menu-list-item-desc">
                          {description}
                        </span>
                      </Link>
                    </li>
                  )
                })}
                {isMobile ? (
                  <li
                    className="site-head-menu-list-item site-head-menu-list-item-x"
                    key={`site-head-menu-list-item-x`}
                    onMouseOver={this.handleMenuHover}
                    onMouseOut={this.handleMenuHover}
                    onClick={this.handleMenuClick}
                    data-section={`Contact`}
                  >
                    <Link
                      to={`contact`}
                      onClick={this.handleMenuClick}
                      className="site-head-menu-list-item-link"
                    >
                      <span className="site-head-menu-list-item-text">
                        {`Work With Us`}
                      </span>
                    </Link>
                  </li>
                ) : (
                  <></>
                )}
              </ul>

              <div className="site-head-menu-list-items--footer">
                Explore our thought leadership on{" "}
                <a
                  href={"#"}
                  className="site-head-menu-list-items--footer-link p-link"
                  onClick={this.handleMenuClick}
                >
                  DISTRIBUTED
                </a>
                .
              </div>
            </div>

            <div className="site-head-menu-preview" ref={`siteHeadMenuPreview`}>
              {PreviewDiv}
            </div>
          </SiteHeadMenuDiv>

          <div className="site-head-work-with-us" ref={`siteHeadWorkWithUs`}>
            <PLink
              to={`contact`}
              onClickFadeOut={true}
              dataText={`Contact`}
              location={location}
            >
              Work With Us
            </PLink>
          </div>
        </SiteHeader>
      </ReactResizeDetector>
    )
  }
}

export default Header
