import React from "react"

import { HeroDivWithSubtitle, HeroDivWithEnumeration, HeroDivWithDescription, SectionAccordionList, SectionWithMultipleContents, EmptySection, HeroDivWithLink } from "./sections";
import { CustomContactDiv } from "./page";
import SEO from "../seo";

import APIHelper from "../../utils/helpers/api"
import { EmptyComponent } from "./base";

import LayoutV2 from "../../components/v2/layout"

export class PageContentBuilder extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loaded : false,
            data: {}
        };
    }

    async componentDidMount() {
        const {api = true, subsection} = this.props;
        console.log("API:", api);
        if(api){
            APIHelper.Get(`/contents/section/${subsection}?json=true`, (err, response) => {
                if (err) {
                    this.setState({ isError: true })
                    console.log(`Something bad happened while fetching the data\n${err}`)
                } else {
                    this.setState({ loaded: true, data: response.data})
                }
            })
        }
        else{
            this.setState({ loaded: true, data: this.props.PageContent})
        }
    }

    render () {
        const {siteTitle, headerActive, location, title, keywords} = this.props, {loaded} = this.state, {contents = []} = this.state.data;
        
        return <LayoutV2 location={location} title={siteTitle} headerActive={headerActive} showFooter={loaded}>
            <SEO title={title} keywords={keywords} />
            {
                loaded ? <article className="psg-section post-content page-template no-image scroller-adjustment animated fadeIn">
                    <div className={"post-content-body"}>
                        {
                            contents.map((item) =>{
                                const {type, content, section, className, contents} = item;
    
                                if(type === "hero_div_with_subtitle"){
                                    return <HeroDivWithSubtitle content={content} className={className}/>
                                }
                                else if(type === "hero_with_enumeration"){
                                    return <HeroDivWithEnumeration content={content} section={section}/>
                                }
                                else if(type === "hero_with_description"){
                                    return <HeroDivWithDescription content={content} section={section}/>
                                }
                                else if(type === "section_accordion_list"){
                                    return <SectionAccordionList content={content} section={section}/>
                                }
                                else if(type === "contact_div"){
                                    return <CustomContactDiv content={content} section={section} sectionLabelRight={`04`} onClickFadeOut={true} ignoreScrollTop={true}/>
                                }
                                else if(type === "section_with_multiple_contents"){
                                    return <SectionWithMultipleContents content={content} section={section}/>
                                }
                                else if(type === "section_div"){
                                    return <EmptySection section={section} contents={contents}/>
                                }
                                else if(type === "hero_div_with_link"){
                                    return <HeroDivWithLink content={content}/>
                                }
                                else{
                                    return <EmptyComponent/>
                                }
                            })
                        }
                    </div>
                </article> :  <EmptyComponent/>
            }
        </LayoutV2> 
    }
}