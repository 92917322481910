module.exports = {
    NavBarLinks : [
        {
            "text" : "Expertise",
            "link" : "/",
            "description" : "Where we excel"
        },
        {
            "text" : "About",
            "link" : "about",
            "description" : "Who we are"
        },
        {
            "text" : "Approach",
            "link" : "approach",
            "description" : "How we do it"
        },
        {
            "text" : "Campus",
            "link" : "campus",
            "description" : "When Space Matters"
        },
        {
            "text" : "Careers",
            "link" : "careers",
            "description" : "Join the team"
        }
    ]
}