module.exports = {
    "title" : "About",
    "contents" : [
        {
            "type" : "hero_div_with_subtitle",
            "className" : "hero-title-div--about", 
            "content" : {
                "hero" : {
                    "text" : "We build and manage highly technical distributed teams for some of the most disruptive companies on the planet",
                    "highlighted" : {
                        "text" : "disruptive",
                        "brush" : {
                            "brushClassName" : "about-brush-hero"
                        } 
                    }
                },
                "subtitle" : [
                    "Clients come to us when they have a need for highly-technical distributed teams and want seasoned experts to help bring them to life. We believe in humanising the outsourcing industry and we do this by finding, attracting and retaining amazingly talented minds, with dynamic workspaces that promote creativity, collaboration, focus and well-being. Finally, we support our clients and employees with industry-leading smart technologies that help put your staff at the center of everything you do."
                ]
            }
        }, 
        {
            "type" : "section_div",
            "section" : {
                "title" : "Stats",
                "number" : "01",
                "className" : "section-div about-section--stats"
            },
            "contents" : [
                {
                    "type" : "column_div",
                    "className" :  "about-section--stats-desc",
                    "content" : {
                        "columns" : [
                            {
                                "text" : {
                                    "text" : "The best talent in the Philippines lives here",
                                    "className" : "medium-sz-text",
                                    "highlighted" : {
                                        "text" : "here",
                                        "brush" : {
                                            "brushClassName" : "about-medium-brush",
                                            "svgClassName" : "medium-brush-type",
                                            "spanClassName" : "medium-brush-animation-text",
                                            "brushType" :   "Brush4",
                                            "viewBox" : "0 -100 100 100"
                                        } 
                                    }
                                },
                            },
                            {
                                "text" : {
                                    "text" : "With our own 20 storey, purpose-built tech campus and industry leading facilities, we're able to attract and retain the very best people in the Philippines. This means you always get access to top-quality talent, and your team are reaching their full potential, as they're able to work in a space that promotes innovation, collaboration and creativity. Our teams operate worldwide, spanning over several time zones and different cultures.",
                                    "className" : "stat-description"
                                }
                            }
                        ]
                    }
                },  
                {
                    "type" : "div_separator",
                    "subsection" : false
                },
                {
                    "type" : "stats_list",
                    "content" : {
                        "list" : [
                            {
                                "number" : "20",
                                "emphasizedDesc" : "Years collective experience",
                                "normalDescription" : "in technology and outsourcing"
                            },
                            {
                                "number" : "500+",
                                "emphasizedDesc" : "Seats and growing",
                                "normalDescription" : "for Fortune 500s, ASX20s, NASDAQ100s, AFR Fast 100s, startups, and small businesses."
                            },
                            {
                                "number" : "3",
                                "emphasizedDesc" : "Years in the business",
                                "normalDescription" : "born in Brisbane, London, Manila and serving customers around the globe"
                            },
                            {
                                "number" : "5",
                                "emphasizedDesc" : "Time zones around the world",
                                "normalDescription" : "we rock around the clock"
                            },
                            {
                                "number" : "8",
                                "emphasizedDesc" : "Countries represented",
                                "normalDescription" : "Australia, Hong Kong, Ireland, Philippines, New Zealand, Sweden, UK, US"
                            },
                            {
                                "number" : "6",
                                "emphasizedDesc" : "PM Friday Happy Hour Drinks",
                                "normalDescription" : "See you there."
                            }
                        ]
                    }
                }
            ]
        },
        {
            "type" : "section_div",
            "section" : {
                "title" : "Clients",
                "number" : "02",
            },
            "contents" : [
                {
                    "type" : "big_text_list",
                    "content" : {
                        "list" : [
                            "Fortune 500",
                            "ASX 100",
                            "ASX 20",
                            "NASDAQ 100",
                            "DELOITTE FAST 50",
                            "AFR FAST 100",
                            "AFR FAST STARTERS"
                        ]
                    }
                },
                {
                    "type" : "plink_arrow",
                    "content" : {
                        "plink" : {
                            "to" : "/",
                            "className" : "p-link p-color--psg-white p-link--animated-hover-left section-description-link",
                            "onClickFadeOut" : true,
                            "ignoreScrollTop" : true,
                            "pinkArrowClassName" : "section-description-link--arrow",
                            "text" : "View Our Expertise"
                        }
                    }
                }
            ]
        },
        {
            "type" : "section_div",
            "section" : {
                "title" : "Client Locations",
                "number" : "03",
            },
            "contents" : [
                {
                    "type" : "image_div",
                    "content" : {
                        "image" : {
                            "src" : "MapSVG",
                            "alt" : "map",
                            "className" : "animated zoomIn"
                        }
                    }
                }
            ]
        },
        {
            "type" : "contact_div",
            "section" : {
                "title" : "Contact",
                "number" : "04",
            },
            "content" : {
                "text" : "Are we the partners you've been searching for? Tell us about your requirements.",
                "highlighted" : "Tell us about your requirements."
            }
        }
    ]
}