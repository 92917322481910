import discovery from "./discovery.svg";
import kickOff from "./kick-off.svg";
import scale from "./scale.svg";
import source from "./source.svg";
import support from "./support.svg";

const index = {
    discovery: discovery,
    kickOff: kickOff,
    scale: scale,
    source: source,
    support: support
};

export default index;