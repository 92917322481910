const environment = process.env.NODE_ENV; 

console.log("current environment:", environment);

const constants = {
    "development" : {
        "api" : {
            url: "http://localhost",
            port: 3500
            // "url" : "https://api.prosource.kloud.ai"
        }
    },
    "production" : {
        "api" : {
            "url" : "https://api.prosource.kloud.ai"
        }
    }
}

const SelectedConfig = constants[environment];

export default SelectedConfig;