import React from "react"
import { graphql, StaticQuery } from "gatsby"

import "../utils/css/v2.css"

import PageContent from "../../content/pages/about";
import { PageContentBuilder } from "../components/partials/page-content-builder";

const AboutPage = ({ data, location}) => {
  const siteTitle = data.site.siteMetadata.title, headerActive = "About", keywords = ["technical distributed teams"];

  return <PageContentBuilder siteTitle={siteTitle} headerActive={headerActive} location={location} title={`About`} keywords={keywords} api={true} PageContent={PageContent} subsection={`about`}/>
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)