import axios from "axios"
import Config from "./config";

const https = require('https');
 
function urlBuilder(url){
    const {port} = url;
    let fullUrl = url.url;

    if(port){
        fullUrl = fullUrl + ":" + port;
    }

    return fullUrl;
}

const APIInstance = axios.create({
    baseURL: urlBuilder(Config.api),
    timeout: 5000,
    headers: {
        "Content-Type" : "application/json"
    },
    httpsAgent: new https.Agent({  
        rejectUnauthorized: false
    })
});

function Get(urlPath = "", callback){
    APIInstance({
        method: "get",
        url: urlPath,
        data: {}
    })
    .then(result =>{
        return callback(null, result);
    })
    .catch(err => {
        return callback(err);
    });
}

function Post({urlPath = "", reqBody}, callback){
    APIInstance({
        method: "post",
        url: urlPath,
        data: reqBody
    })
    .then(result =>{
        return callback(null, result);
    })
    .catch(err => {
        return callback(err);
    });
}

function Put({urlPath = "", reqBody}, callback){
    // axios.put(`${urlBuilder(Config.api)}${urlPath}`, reqBody)
    // .then(response => {
    //     return callback(null, response);
    // })
    // .catch(error => {
    //     return callback(error);
    // });

    APIInstance({
        method: "put",
        url: urlPath,
        data: reqBody
    })
    .then(result =>{
        return callback(null, result);
    })
    .catch(err => {
        return callback(err);
    });
}


const APIRequest = {
    Get: Get,
    Post: Post,
    Put: Put,
};

export default APIRequest;
